import request from '../Axios'

// 新增O、编辑
export const activity = data => {
  return request({
    url: '/template/share/saveOrUpdate',
    method: 'post',
    data
  })
}

// 查询
export const shareInfo = data => {
  return request({
    url: '/template/share',
    method: 'get'
  })
}

// 查询奖励项
export const rewardList = params => {
  return request({
    url: '/template/share/rewardList',
    method: 'get'
  })
}

